<template>
  <b-container class="col">
    <b-card>
      <b-row>
        <b-col cols="7">
          <b-button
            variant="gradient-primary"
            class="mr-1"
            @click="generateAddDrivers"
          >
            <span class=""> ADD DEPARTMENT </span>
            <feather-icon
              icon="PlusCircleIcon"
              size="14"
              class="text-white ml-1"
            />
          </b-button>
        </b-col>
        <b-col cols="5">
          <!-- search input -->
          <div class="d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search all field"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        style-class="vgt-table"
      >
        <template slot="table-row" slot-scope="props">
          <template
            v-if="
              props.column.field === 'id' ||
              props.column.field === 'permissions' ||
              props.column.field === 'action'
            "
          >
            <span v-if="props.column.field == 'id'">
              <b-button variant="gradient-primary" block>{{
                props.row.id
              }}</b-button>
            </span>
            <span v-if="props.column.field == 'permissions'">
              <b-button
                v-for="(Permission, index) in props.row.permissions"
                :key="index"
                variant="gradient-primary"
                block
              >
                {{ Permission.menu_name }}
              </b-button>
            </span>

            <span v-if="props.column.field == 'action'">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="EditShow(props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="deleteDepartment(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <validation-observer ref="simpleRules">
      <b-modal
        ref="generateAddDrivers"
        title="Add Department"
        hide-footer
        centered
        no-close-on-backdrop
      >
        <div class="my-2">
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col cols="9"
              ><validation-provider
                #default="{ errors }"
                name="Department"
                rules="required"
              >
                <input
                  v-model="form.role_name"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  placeholder="Department"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-col
            >
            <b-col cols="3"
              ><b-form-checkbox
                id="checkbox-1"
                v-model="form.role_type"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                HQ User
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>

        <div class="mb-2">
          <validation-provider
            #default="{ errors }"
            name="Select Authority"
            rules="required"
          >
            <v-select
              v-model="form.permission"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              placeholder="Add Access Level"
              :options="menu"
              label="menu_name"
              :reduce="(menu) => menu.menu_id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="d-flex justify-content-center mb-2">
          <b-button variant="gradient-primary" @click.prevent="addDepartment">
            SUBMIT
          </b-button>
        </div>
      </b-modal>
    </validation-observer>
    <b-modal
      ref="generateEditDepartment"
      title="Edit Department"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div class="my-2">
        <b-row class="d-flex align-items-center justify-content-center">
          <b-col cols="9">
            <input
              v-model="formEdit.role_name"
              type="text"
              class="form-control"
              placeholder="Department"
          /></b-col>
          <b-col cols="3"
            ><b-form-checkbox
              id="checkbox-1"
              v-model="formEdit.role_type"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
              HQ User
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

      <div class="mb-2">
        <v-select
          v-model="editPermission2"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          placeholder="Add Access Level"
          :options="menu"
          label="menu_name"
          :reduce="(menu) => menu.menu_id"
        />
      </div>

      <div class="d-flex justify-content-center mb-2">
        <b-button variant="gradient-primary" @click.prevent="EditDepartment">
          SUBMIT
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ToastificationContent,
  },

  data() {
    return {
      id: "",
      menu: [],
      required,
      optionsDepartment: [
        { value: null, text: "Department" },
        { value: "High Level Management", text: "High Level Management" },
        { value: "Human Resource & Admin", text: "Human Resource & Admin" },
        { value: "Business Development", text: "Business Development" },
        { value: "Outlet", text: "Outlet" },
        { value: "IT Management", text: "IT Management" },
        { value: "IT Engineers", text: "IT Engineers" },
        { value: "Finance", text: "Finance" },
        { value: "Operation & Warehouse", text: "Operation & Warehouse" },
        { value: "Driver", text: "Driver" },
      ],
      selected: null,
      branch: [],
      rows: [],
      form: {
        role_name: null,
        role_type: 0,
      },
      form2: {},
      editPermission: [],
      editPermission2: [],
      formEdit: {
        role_name: "",
        permission: "",
        role_type: 0,
      },
      pageLength: 5,
      searchTerm: "",
      columns: [
        {
          label: "ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Department",
          field: "role_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Permission",
          field: "permissions",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.getallRoles();
      this.getallMenu();
    },

    getallRoles() {
      this.$http.get(`roles`).then((response) => {
        if (response.data.status) {
          this.rows = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    getallMenu() {
      this.$http.get(`menu`).then((response) => {
        if (response.data.status) {
          this.menu = response.data.data;
        } else {
          this.$swal({
            title: "Error !",
            icon: "warning",
            text: response.data.message,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
      });
    },

    generateAddDrivers() {
      this.$refs.generateAddDrivers.show();
    },

    addDepartment() {
      const self = this;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.form2.role_name = this.form2.role_name;
          this.form2.permission = this.form2.permission;
          this.sending = true;
          this.$http.post(`roles`, this.form).then((response) => {
            if (response.data.status) {
              self.sending = false;
              this.$swal({
                title: "Successfully Added Department !",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$refs.generateAddDrivers.hide();
              self.form = {};
              this.getallRoles();
            } else {
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            }
          });
        }
      });
    },

    EditShow(row) {
      this.editPermission2 = [];
      this.form2 = { ...row };
      this.formEdit.role_name = this.form2.role_name;
      this.formEdit.role_type = this.form2.role_type;
      this.editPermission = this.form2.permissions.map((item) => item.menu_id);
      for (let x = 0; x < this.editPermission.length; x++) {
        for (let y = 0; y < this.menu.length; y++) {
          if (this.editPermission[x] == this.menu[y].menu_id) {
            this.editPermission2.push(this.menu[y]);
          }
        }
      }
      this.$refs.generateEditDepartment.show();
    },

    EditDepartment() {
      const self = this;
      self.sending = true;
      this.formEdit.permission = this.editPermission2;
      if (this.formEdit.permission[0].menu_id) {
        this.formEdit.permission = this.editPermission2.map(
          (item) => item.menu_id
        );
      }
      this.$http
        .put(`roles/${this.form2.id}`, this.formEdit)
        .then((response) => {
          if (response.data.status) {
            self.sending = false;
            this.$swal({
              title: "Successfully Updated Department !",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.$refs.generateEditDepartment.hide();
            self.form2 = {};
            this.getallRoles();
          } else {
            if (response.data.errors) {
              var errors = response.data.errors;
              for (var a in errors) {
                var error = errors[a];
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error[0],
                    icon: "XIcon",
                    variant: "warning",
                  },
                });
              }
              this.$swal({
                title: "Error !",
                icon: "warning",
                text: response.data.message,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "XIcon",
                  variant: "warning",
                },
              });
            }
          }
        });
      this.editPermission2 = [];
    },

    deleteDepartment(row) {
      const self = this;
      this.$swal({
        title: "Are you sure?",
        text: row.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          self.sending = true;
          const response = await this.$http.delete(`roles/${row}`);
          if (response.data.status) {
            this.getallRoles();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
</style>
